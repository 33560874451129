import React from "react";
import styled from "styled-components";
import { grey } from "../../../colors";

export const SubcategoryQuestionList = ({
  subCategory,
  id,
  Link,
  ...props
}) => {
  return (
    <SubCategory {...props}>
      <h2>
        <a id={`category-${id}`} />
        {subCategory.name}
      </h2>
      <QuestionsWrapper>
        {subCategory.question &&
          subCategory.question
            .filter((question) => !!question.seoMeta && !!question.seoMeta.slug)
            .map(
              (question) =>
                question.seoMeta && (
                  <Item>
                    <Question>
                      <Link to={`${question.seoMeta.slug}/`}>
                        {question.question && question.question.question}
                      </Link>
                    </Question>
                    {question.seoMeta.description && (
                      <dd>{question.seoMeta.description.slice(0, 80)}...</dd>
                    )}
                  </Item>
                )
            )}
      </QuestionsWrapper>
    </SubCategory>
  );
};

const SubCategory = styled.section`
  ${(props) =>
    props.borderTop
      ? `
  margin-top: 42px;
  padding-top: 42px;
  border-top: 1px solid ${grey};`
      : `
  margin-bottom: 42px;
  padding-bottom: 42px;
  border-bottom: 1px solid ${grey};`}
  h2 {
    margin-bottom: 32px;
  }
  a {
    font-weight: bold;
  }
`;
const Item = styled.div`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Question = styled.dt`
  margin-bottom: 0.5em;
`;
const QuestionsWrapper = styled.dl``;
