import React from "react";
import styled, { ThemeProvider } from "styled-components";

export default ({ children }) => {
  return (
    <LayoutWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </LayoutWrapper>
  );
};

const ContentWrapper = styled.div`
  margin: 0 auto;
`;

const LayoutWrapper = styled.div``;
