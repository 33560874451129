import React from "react";
import { LocaleLink as Link } from "gatsby-theme-twill-website";
import styled from "styled-components";
import Layout from "../layouts/main";
import { graphql } from "gatsby";
import { Header } from "../components/Header";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Section } from "site-ui/src/components/Section";
import { SubcategoryQuestionList } from "site-ui/src/components/SubcategoryQuestionList";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { getSubcategoriesList } from "site-ui/src/helpers/getSubcategoriesList";
import { uniqBy } from "lodash";

const LinkWrapped = ({ to, children }) => <Link to={to}>{children}</Link>;

export default ({ data }) => {
  const { contentfulCategory: category } = data;
  const subcategories = getSubcategoriesList(category);

  return (
    <PageWrapper>
      <Breadcrumbs category={category.name} />
      <Header headline={category.name} theme={"grey"} />
      <Section>
        <Wrapper>
          <aside>
            <Nav>
              {subcategories?.map((subCategory, key) => (
                <a href={`#category-${key}`}>{subCategory.name}</a>
              ))}
            </Nav>
          </aside>
          <main>
            {subcategories?.map((subCategory, key) => (
              <SubcategoryQuestionList
                subCategory={{
                  ...subCategory,
                  question: uniqBy(
                    [
                      ...(subCategory.questionsOrdered || []),
                      ...(subCategory.question || []),
                    ],
                    "contentful_id"
                  ),
                }}
                id={key}
                Link={LinkWrapped}
              />
            ))}
          </main>
        </Wrapper>
      </Section>
    </PageWrapper>
  );
};

export const query = graphql`
  fragment QuestionInfo on ContentfulQuestion {
    contentful_id
    question {
      question
    }
    seoMeta {
      slug
      title
      description
    }
  }
  fragment SubCategoryListItem on ContentfulSubCategory {
    questionsOrdered: questions {
      ...QuestionInfo
    }
    question {
      ...QuestionInfo
    }
    contentful_id
    name
  }
  query($locale: String!, $contentful_id: String!) {
    contentfulCategory(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      contentful_id
      name
      node_locale
      slug
      updatedAt
      seoMeta {
        slug
        title
        description
        previewImage {
          file {
            url
          }
        }
      }
      subcategory {
        ...SubCategoryListItem
      }
      subCategories {
        ...SubCategoryListItem
      }
    }
  }
`;
// export const pageQuery = graphql`
//   query test($locale: String!) {
//     locales: allLocale(
//       filter: { ns: { in: ["twill-net"] }, language: { eq: $locale } }
//     ) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `

const Nav = styled.nav`
  display: grid;
  grid-gap: 16px;
  grid-direction: row;
  position: sticky;
  top: 144px;
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 60px;
  max-width: 1200px;
  margin: 0 auto;
  h1 {
    margin: 20px 0 40px;
    font-size: 32px;
    color: #142146;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
