import React from "react";
import { LocaleLink as Link, useLocale } from "gatsby-theme-twill-website";
import { Breadcrumbs as BreadcrumbsBase } from "site-ui/src/components/Breadcrumbs";
import { useTranslation } from "twill-net-localization";
import { Sections } from "../../../../tools/ui/constants";
import { getSectionRoute } from "../../../../tools/ui/helpers/getSectionRoute";

export const Breadcrumbs = ({ category, question }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const items = [
    <Link to={`/${getSectionRoute(locale, Sections.FAQ)}/`}>
      {t("common.faqShort", "FAQ")}
    </Link>,
  ];
  if (category && category.seoMeta?.slug) {
    items.push(
      <Link
        to={`/${getSectionRoute(locale, Sections.FAQ)}/${
          category.seoMeta?.slug
        }/`}
      >
        {category.name}
      </Link>
    );
  }
  if (category && !category.slug) {
    items.push(<span>{category}</span>);
  }
  if (question) {
    items.push(<span>{question}</span>);
  }
  return <BreadcrumbsBase items={items} />;
};
